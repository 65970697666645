import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import LightboxProvider from "./context/LightboxContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<LightboxProvider>
			<App />
		</LightboxProvider>
	</React.StrictMode>
);
reportWebVitals();
